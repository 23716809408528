import { CardMedia, makeStyles } from '@material-ui/core';
import React from 'react';
import ChooseLinkButton from './choose-link-button';

interface Props {
  title: string;
  url: string;
  image?: string;
  miniTitle?: string;
  paragraph?: string;
  linkText?: string;
}
const useStyles = makeStyles(() => ({
  image: {
    height: 80,
    minWidth: 80,
    borderRadius: 4,
    marginLeft: '22px',
  },
  button: {
    width: '100%',
    background: '#880226',
    color: '#ffffff',
    marginTop: '10px',
    '&:hover': {
      backgroundColor: 'rgba(136, 2, 38,0.9)',
    },
  },
}));
const LinkCardMobile: React.FC<Props> = ({
  title,
  url,
  image,
  paragraph,
  linkText = '',
}: Props) => {
  const classes = useStyles();

  return (
    <div className="card">
      <div className="text-container">
        <div>
          <h1 className="title">{title}</h1>
          <p className="text">{paragraph}</p>
        </div>
        <CardMedia className={classes.image} image={image}></CardMedia>
      </div>
      <div className="link-container">
        <ChooseLinkButton url={url} text={linkText}></ChooseLinkButton>
      </div>
      <style jsx>{`
        .link-container {
          width: 100%;
        }
        .card {
          display: flex;
          flex-direction: column;
          background: #ffffff;
          filter: drop-shadow(0px 1px 3px #00000033);
          align-items: center;
          padding: 11.5px;
          margin-bottom: 20px;
          border-radius: 4px;
        }
        .text-container {
          display: flex;
          flex-direction: row;
        }
        .title {
          margin: 0;
          font-family: 'Inter', sans-serif;
          font-weight: 900;
          font-size: 20px;
          text-transform: uppercase;
          margin-bottom: 6px;
        }
        .text {
          margin: 0;
          margin-bottom: 10px;
          font-family: 'Inter', sans-serif;
          font-size: 12px;
        }
        img {
          height: 80px;
          width: 80px;
        }
      `}</style>
    </div>
  );
};

export default LinkCardMobile;
