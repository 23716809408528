import { Grid, makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'next-i18next';
import React from 'react';
import CardLink from '../components/link-card';
import LinkCardMobile from '../components/link-card-mobile';

const useStyles = makeStyles(() => ({
  leftContainer: {
    maxWidth: '800px',
  },
  title: {
    fontSize: '3.75rem',
    letterSpacing: '-0.53px',
    marginBottom: 10,
    fontFamily: 'Inter, sans-serif',
    fontWeight: 400,
  },
  subTitle: {
    fontSize: '1.25rem',
    letterSpacing: '0.26px',
    marginBottom: 26,
    fontWeight: 500,
    lineHeight: '1.24em',
  },
  paragraph: {
    letterSpacing: '0.49px',
    lineHeight: '1.19em',
  },
  card: {
    marginRight: 30,
    marginBottom: 30,
  },
}));

const HomePageContainer: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation(['home-page']);
  return (
    <Grid container wrap="nowrap" direction="row">
      <Grid className={classes.leftContainer} direction="column" container item>
        <h1 className="title">{t('title')}</h1>
        <Typography className={classes.paragraph} paragraph>
          {t('paragraph1')}
        </Typography>
        <Typography className={classes.paragraph} paragraph>
          {t('paragraph2')}
        </Typography>
        <Typography className={classes.paragraph} paragraph>
          {t('paragraph3')}
        </Typography>
        <Typography className={classes.subTitle} variant="h6">
          {t('subtitle')}
        </Typography>

        <div className="desktop-card-container">
          <Grid className={classes.card} item>
            <CardLink
              icon="/static/images/gdpr_icon.png"
              image="/static/images/gdpr.png"
              url="/gdpr/sign"
              title={t('link-cards.gdprTransfer.headline')}
              header={t('link-cards.gdprTransfer.header')}
              paragraph={t('link-cards.gdprTransfer.paragraph')}
            ></CardLink>
          </Grid>
          <Grid className={classes.card} item>
            <CardLink
              image="/static/images/normal.png"
              url="/transfer"
              title={t('link-cards.normalTransfer.headline')}
              icon="/static/images/paper.png"
              paragraph={t('link-cards.normalTransfer.paragraph')}
              header={t('link-cards.normalTransfer.header')}
            ></CardLink>
          </Grid>
        </div>
        <div className="mobile-card-container">
          <LinkCardMobile
            image="/static/images/gdpr.png"
            url="/gdpr/sign"
            title={t('link-cards.gdprTransfer.header')}
            paragraph={t('link-cards.gdprTransfer.paragraph')}
            linkText={t('chooseButton')}
          ></LinkCardMobile>
          <LinkCardMobile
            image="/static/images/normal_small.png"
            url="/transfer"
            title={t('link-cards.normalTransfer.header')}
            paragraph={t('link-cards.normalTransfer.paragraph')}
            linkText={t('chooseButton')}
          ></LinkCardMobile>
        </div>
      </Grid>
      <style jsx>{`
        .title {
          display: none;
        }
        .desktop-card-container {
          display: none;
        }
        .mobile-card-container {
        }
        @media only screen and (min-width: 800px) {
          .mobile-card-container {
            display: none;
          }
          .desktop-card-container {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
          }
          .title {
            display: block;
            font-size: '3.75rem';
            letter-spacing: '-0.53px';
            margin-bottom: 10;
            font-family: 'Inter', sans-serif;
            font-weight: 400;
          }
        }
      `}</style>
    </Grid>
  );
};

export default HomePageContainer;
