import { makeStyles } from '@material-ui/core';
import React from 'react';
const useStyles = makeStyles(() => ({
  button: {
    width: '100%',
    background: '#880226',
    padding: 5,
    color: '#ffffff',
    marginTop: '10px',
    textAlign: 'center',
    borderRadius: 4,
    '&:hover': {
      backgroundColor: 'rgba(136, 2, 38,0.9)',
    },
  },
}));
type ChooseLinkButtonProps = {
  url: string;
  text: string;
  blank?: boolean;
};
const ChooseLinkButton: React.FC<ChooseLinkButtonProps> = ({
  url,
  text,
  blank = false,
}: ChooseLinkButtonProps) => {
  const classes = useStyles();
  return (
    <a href={url} target={blank ? '_blank' : ''} rel="noreferrer">
      <div className={classes.button}>{text}</div>
    </a>
  );
};

export default ChooseLinkButton;
