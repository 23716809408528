import { Card, CardContent, CardMedia, Grid, makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import React, { FC } from 'react';

const useStyles = makeStyles(() => ({
  image: {
    fontColor: 'black',
    height: 160,
    width: 344,
    position: 'relative',
    background: '#003399',
  },
  cardHeader: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    color: 'white',
    fontFamily: 'Inter, sans-serif',
    fontSize: '1.25rem',
    fontWeight: 900,
    letterSpacing: '0.26px',
    lineHeight: '1.24em',
    margin: 15,
  },
  miniTitle: {
    fontSize: '0.75rem',
    lineHeight: '1.24em',
    fontFamily: 'Inter, sans-serif',
    textTransform: 'uppercase',
  },
  title: {
    fontSize: '1.25rem',
    lineHeight: '1.24em',
    fontWeight: 500,
    marginBottom: 10,
  },
  paragraph: {
    fontSize: '0.875rem',
    lineHeight: '1.24em',
    fontWeight: 400,
    marginBottom: 10,
  },
  choose: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '0.875rem',
    fontWeight: 'bold',
    letterSpacing: '0.26px',
    lineHeight: '1.24em',
    color: '#E20815',
    textTransform: 'uppercase',
  },
  cardContent: {
    background: 'transparent',
  },
  contentGrid: {
    height: '100%',
  },
  card: {
    width: '344px',

    '&:hover': {
      background: '#E4EAFD',
    },
  },
  icon: {
    height: 40,
    width: 40,
  },
}));
interface Props {
  title: string;
  url: string;
  image: string;
  icon: string;
  header: string;
  miniTitle?: string;
  paragraph: string;
}

const CardLink: FC<Props> = ({ header, title, url, image, icon, miniTitle, paragraph }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation('home-page');
  return (
    <Link passHref={true} href={url}>
      <a href={url}>
        <Card className={classes.card}>
          <CardMedia image={image} className={classes.image}>
            <Typography className={classes.cardHeader}>{header}</Typography>
          </CardMedia>
          <CardContent className={classes.cardContent}>
            <Grid
              className={classes.contentGrid}
              container
              direction="column"
              justifyContent="space-between"
            >
              <Grid container justifyContent="space-between">
                <Grid item>
                  {miniTitle && <Typography className={classes.miniTitle}>{miniTitle}</Typography>}
                  <Typography className={classes.title}>{title}</Typography>
                </Grid>
                <Grid item>
                  <CardMedia className={classes.icon} image={icon}></CardMedia>
                </Grid>
              </Grid>
              <Typography className={classes.paragraph} paragraph>
                {paragraph}
              </Typography>

              <Typography className={classes.choose}>{t('link-cards.choose')}</Typography>
            </Grid>
          </CardContent>
        </Card>
      </a>
    </Link>
  );
};

export default CardLink;
