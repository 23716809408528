import { GetServerSideProps } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { FC } from 'react';
import Layout from '../components/layout';
import HomePageContainer from '../containers/home-page-container';

const Home: FC = () => {
  return (
    <Layout showSubtitle={true} title="home">
      <HomePageContainer></HomePageContainer>
    </Layout>
  );
};
export const getServerSideProps: GetServerSideProps = async ({ locale }: any) => {
  return {
    props: { ...(await serverSideTranslations(locale, ['common', 'home-page', 'link-cards'])) },
  };
};
export default Home;
